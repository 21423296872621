import { getAnzahlTiere, createNewElement, calculateDogAge, getSelectedProductName } from "./helper.js";


const _createSelectedProduct = (product) => {
    const productContainer = createNewElement('div');
    const label = createNewElement('div');

    productContainer.classList.add('row', 'tierversicherung-produkt-element-gewuenscht');
    label.classList.add('econ-overview__label');
    label.innerHTML = product + ' ';
    label.appendChild(_getSelectedTarif());
    productContainer.appendChild(label);

    return productContainer;
}

const _isOldDog = (index) => {
    const dogBirthdays = document.getElementById('ue-hundealter').innerText.split(' | ');
    let isOldDog = false, dogAge;

        dogAge = calculateDogAge(dogBirthdays[index]);
        if (parseFloat(dogAge) >= 6) {
            isOldDog = true;
        }


    return isOldDog;

}

const _hasTraining = (index) => {
    const dogTrainings = document.getElementById('ue-ausbildung').innerText.split(' | ');
    const dogHasTraining = dogTrainings[index];

    return dogHasTraining;
}

const _createDiscountContainer = () => {
    const notInProcessDiscountNames = ['duration', 'online', 'kombi', 'loyal', 'age', 'training'];
    const anzahlTiere = getAnzahlTiere();
    const discountContainer = createNewElement('div');

    const loyalDiscount = document.querySelector('.tierversicherung-versicherungsbeitrag-rabatte-treue');
    const loyalDiscountLabel = (loyalDiscount != undefined) ? loyalDiscount.querySelector('.econ-overview__label').innerText : null;
    const onlineDiscount = document.querySelector('.tierversicherung-versicherungsbeitrag-rabatte-online');

    const labelContainer = createNewElement('div');
    const valuesContainer = createNewElement('div');

    for (let i = 0; i < notInProcessDiscountNames.length; i++) {
        let valueContainer = createNewElement('span');
        let discountValue = '5 % ';
        valueContainer.classList.add('ue-discount');

        if (i < (notInProcessDiscountNames.length)) {
            let discountName = notInProcessDiscountNames[i];
            let notInProcessDiscountValue = (document.querySelector('.ue-' + discountName + '-discount span')) ? document.querySelector('.ue-' + discountName + '-discount span').innerText : null;
            if (notInProcessDiscountValue.toLowerCase() !== 'nein') {

                switch (discountName) {
                    case 'duration':
                        discountValue += 'Laufzeit-Rabatt';
                        valueContainer.innerText = discountValue;
                        break;
                    case 'online':
                        discountValue += 'Papierlos-Rabatt';
                        valueContainer.innerText = discountValue;
                        break;
                    case 'kombi':
                        discountValue += 'Kombi-Rabatt';
                        valueContainer.innerText = discountValue;
                        break;
                    case 'loyal':
                        discountValue += 'Treue-Rabatt';
                        valueContainer.innerText = discountValue;
                        break;
                    case 'age':
                        for (let z = 0; z < anzahlTiere; z++) {
                            if (_isOldDog(z)) {
                                let ageValueContainer = createNewElement('span');
                                let animalName = (document.querySelector('.ue-discount__animal-name-' + z + ' span')) ? document.querySelector('.ue-discount__animal-name-' + z + ' span').innerText : null;
                                discountValue = notInProcessDiscountValue;
                                ageValueContainer.classList.add('ue-discount');
                                ageValueContainer.innerText = discountValue + ' ' + animalName;
                                valuesContainer.append(ageValueContainer);
                            }
                        }
                        break;
                    case 'training':
                        for (let z = 0; z < anzahlTiere; z++) {
                            if (_hasTraining(z) !== 'keine davon' && _hasTraining(z)) {
                                let trainingValueContainer = createNewElement('span');
                                let animalName = (document.querySelector('.ue-discount__animal-name-' + z + ' span')) ? document.querySelector('.ue-discount__animal-name-' + z + ' span').innerText : null;
                                discountValue = notInProcessDiscountValue;
                                trainingValueContainer.classList.add('ue-discount');
                                trainingValueContainer.innerText = discountValue + ' ' + animalName;
                                valuesContainer.append(trainingValueContainer);                         
                            }
                        }

                        break;    
                    default:
                        break;
                }

                valuesContainer.append(valueContainer);
            }
        }
    }


    discountContainer.classList.add('row', 'tierversicherung-versicherungsbeitrag-rabatte');
    labelContainer.classList.add('econ-overview__label');
    labelContainer.innerText = 'Deine Rabatte';
    valuesContainer.classList.add('econ-overview__value');

    discountContainer.append(labelContainer, valuesContainer);
    if (loyalDiscount != null) loyalDiscount.remove();
    if (onlineDiscount != null) onlineDiscount.remove();

    return discountContainer;
}

const _createPriceContainer = () => {
    const productPrice = document.getElementById('ermittelterPreis').innerText;
    const priceContainer = createNewElement('div');
    const labelContainer = createNewElement('div');
    const valueContainer = createNewElement('div');

    priceContainer.classList.add('row', 'tierversicherung-versicherungsbeitrag-ermittelterPreis');
    labelContainer.classList.add('econ-overview__label');
    labelContainer.innerText = 'Dein Preis gemäß Zahlungsintervall';
    valueContainer.classList.add('econ-overview__value');
    valueContainer.innerText = productPrice + ' €';

    priceContainer.append(labelContainer, valueContainer);

    return priceContainer;
}

const _getSelectedTarif = () => {
    const productIndex = (getSelectedProductName() === 'OP-Versicherung') ? 0 : 1;
    const tarif = document.querySelector('.tierversicherung-produkt-elemente-' + productIndex + '-produktName .econ-overview__value').innerText;
    const tarifWrapper = createNewElement('i');

    tarifWrapper.innerText = tarif;

    return tarifWrapper;
}

const _getZusatzbausteine = () => { // TODO ggfs. nach refactoring anpassen
    const reha = document.querySelector('.tierversicherung-zusatzProdukt-elemente-3-gewuenscht');
    const sonderOp = document.querySelector('.tierversicherung-zusatzProdukt-elemente-0-gewuenscht');
    const zahnZusatz = document.querySelector('.tierversicherung-zusatzProdukt-elemente-4-gewuenscht');
    let rehaLabel = reha.querySelector('.econ-overview__label');
    let sonderOpLabel = sonderOp.querySelector('.econ-overview__label');
    let zahnZusatzLabel = zahnZusatz.querySelector('.econ-overview__label');
    let zusatzbausteine = document.createDocumentFragment();

    if (reha) {
        rehaLabel.innerText = 'Reha Baustein';
        zusatzbausteine.appendChild(reha);
    }
    if (sonderOp) {
        sonderOpLabel.innerText = 'Sonder-OP Baustein';
        zusatzbausteine.appendChild(sonderOp);
    }
    if (zahnZusatz) {
        zahnZusatzLabel.innerText = 'Zahnzusatz Baustein';
        zusatzbausteine.appendChild(zahnZusatz);
    }

    return zusatzbausteine;
}

const _getCrossSellingHafRow = () =>  document.querySelector('.tierversicherung-produkt-elemente-3-gewuenscht');

const _getCrossSellingBestLeistungRow = () => document.querySelector('.tierversicherung-zusatzProdukt-elemente-1-gewuenscht');

const _getCrossSellingHsRow = () => document.querySelector('.tierversicherung-produkt-elemente-4-gewuenscht');

const _getCrossSellingDeckungssumme = () => {
    const deckungRow = document.querySelector('.tierversicherung-produkt-elemente-3-deckungssumme');
    const deckungLabel = deckungRow.querySelector('.econ-overview__label');

    deckungLabel.innerText = 'Deckungssumme';

    return deckungRow;
}

const _getCrossSellingSb = () => {
    const sbRow = document.querySelector('.tierversicherung-produkt-elemente-3-selbstbeteiligung');
    const sbLabel = sbRow.querySelector('.econ-overview__label');

    sbLabel.innerText = 'Selbstbeteiligung';

    return sbRow;
}

const _getCrossSellingVorversicherungsData = () => {
    const vorversicherungVorhanden = document.querySelector('.tierversicherung-produkt-elemente-4-vorversicherung-vorhanden');
    const versicherungsNameRow = document.querySelector('.tierversicherung-produkt-elemente-4-vorversicherung-versicherungsName');
    const versicherungsNrRow = document.querySelector('.tierversicherung-produkt-elemente-4-vorversicherung-versicherungsNummer');
    const versicherungGekuendigtRow = document.querySelector('.tierversicherung-produkt-elemente-4-vorversicherung-gekuendigt');
    const kuendigungsdatumRow = document.querySelector('.tierversicherung-produkt-elemente-4-vorversicherung-kuendigungsdatum');
    const kuendigungDurchVersicherungRow = document.querySelector('.tierversicherung-produkt-elemente-4-vorversicherung-kuendigungDurchVersicherung');

    let crossSellingVorversicherungsDataOut = document.createDocumentFragment();

    if (_hasCrossSellingHsVorversicherung()) {
        crossSellingVorversicherungsDataOut.appendChild(vorversicherungVorhanden);
        if (versicherungsNameRow) crossSellingVorversicherungsDataOut.appendChild(versicherungsNameRow);
        if (versicherungsNrRow) crossSellingVorversicherungsDataOut.appendChild(versicherungsNrRow);
        if (versicherungGekuendigtRow) crossSellingVorversicherungsDataOut.appendChild(versicherungGekuendigtRow);
        if (kuendigungsdatumRow) crossSellingVorversicherungsDataOut.appendChild(kuendigungsdatumRow);
        if (kuendigungDurchVersicherungRow) crossSellingVorversicherungsDataOut.appendChild(kuendigungDurchVersicherungRow);
    } else {
        crossSellingVorversicherungsDataOut.appendChild(vorversicherungVorhanden);
    }

    return crossSellingVorversicherungsDataOut;
}

const _hasCrossSellingHsVorversicherung = () => {
    const vorversicherungRow = document.querySelector('.tierversicherung-produkt-elemente-4-vorversicherung-vorhanden');
    const vorversicherungVorhanden = (vorversicherungRow && vorversicherungRow.querySelector('.econ-overview__value').innerText === 'ja') ? true : false;

    return vorversicherungVorhanden;
}

const _crossSellingHafGewuenscht = () =>{
    const hafRow = _getCrossSellingHafRow();
    const hafGewuenscht = (hafRow && hafRow.querySelector('.econ-overview__value').innerText === 'ja') ? true : false;

    return hafGewuenscht;
}

const _crossSellingBestLeistungGewuenscht = () => {
    const bestLeistungRow = _getCrossSellingBestLeistungRow();
    const bestLeistungGewuenscht = (bestLeistungRow && bestLeistungRow.querySelector('.econ-overview__value').innerText === 'ja') ? true : false;

    return bestLeistungGewuenscht;
}

const _crossSellingHsGewuenscht = () => {
    const hsRow = _getCrossSellingHsRow();
    const hsGewuenscht = (hsRow && hsRow.querySelector('.econ-overview__value').innerText === 'ja') ? true : false;

    return hsGewuenscht;
}

const _createCrossSellingHaf = () => {
    if (_getCrossSellingHafRow() == null) return;
    const hafRow = _getCrossSellingHafRow();
    const hafPanel = hafRow.closest('section').parentElement;
    const hafValue = hafRow.querySelector('.econ-overview__value');
    const baustein = _getCrossSellingBestLeistungRow();
    let hafRowOut = document.createDocumentFragment();

    if (_crossSellingHafGewuenscht()) {

        hafRowOut.appendChild(hafRow);
        hafRowOut.appendChild(_getCrossSellingDeckungssumme());
        hafRowOut.appendChild(_getCrossSellingSb());
        if (_crossSellingBestLeistungGewuenscht()) hafRowOut.appendChild(baustein);
        _sortDogData();
    }

    hafValue.remove();
    hafPanel.remove();
    return hafRowOut;
}

const _createCrossSellingHs = () => {
    if (_getCrossSellingHsRow() == null) return;
    const hsRow = _getCrossSellingHsRow();
    const hsPanel = hsRow.closest('section').parentElement;
    const hsValue = hsRow.querySelector('.econ-overview__value');
    let hsRowOut = document.createDocumentFragment();
console.log(hsRow, hsPanel);
    if (_crossSellingHsGewuenscht()) {
        hsRowOut.appendChild(hsRow)
        hsRowOut.appendChild(_getCrossSellingVorversicherungsData());
    }

    hsValue.remove();
    hsPanel.remove();
    return hsRowOut;
}

const sortTarifData = () => {
    const crossSellingId = document.getElementById('crossSellingId').innerText;
    const bestandskunde = document.querySelector('.antrag-antragsteller1-kunde-vorhanden');
    const parentSectionContainer = bestandskunde.closest('section').parentElement;
    const targetSection = document.querySelector('.tierversicherung-versicherungsbeginn').parentElement;
    const selectedProduct = getSelectedProductName();
    const productContainer = _createSelectedProduct(selectedProduct);
    const zusatzBausteine = _getZusatzbausteine();
    const discountsContainer = _createDiscountContainer();
    const produktPreis = _createPriceContainer();
    let crossSellingHaf = document.createDocumentFragment(), crossSellingHs = document.createDocumentFragment();

    if (crossSellingId === 'op/kv cross' || crossSellingId === 'kv cross') {
        let title = targetSection.parentElement.previousElementSibling;

        crossSellingHaf.append(_createCrossSellingHaf());
        crossSellingHs.append(_createCrossSellingHs());

        if (crossSellingHaf.children.length > 0 || crossSellingHs.children.length > 0) {
            title.innerText = 'Deine Versicherungen';
        }

    }

    document.querySelector('.tierversicherung-versicherungsbeginn .econ-overview__label').textContent = 'Versicherungsbeginn';
    targetSection.prepend(productContainer, zusatzBausteine, crossSellingHaf, crossSellingHs, bestandskunde);
    parentSectionContainer.remove();
    targetSection.append(discountsContainer, produktPreis);
}

const sortPartnerData = () => {
    const labelSelector = '.econ-overview__label';
    const valueSelector = '.econ-overview__value';
    const nameField = document.querySelector('.antrag-antragsteller1-personenDaten-vorname');
    const nachnameField = document.querySelector('.antrag-antragsteller1-personenDaten-nachname');
    const plzField = document.querySelector('.antrag-antragsteller1-personenDaten-adresse-plz');
    const ortField = document.querySelector('.antrag-antragsteller1-personenDaten-adresse-ort');
    const strasseField = document.querySelector('.antrag-antragsteller1-personenDaten-adresse-strasse');
    const nrField = document.querySelector('.antrag-antragsteller1-personenDaten-adresse-hausnummer');
    const geburtsdatum = document.querySelector('.antrag-antragsteller1-personenDaten-geburtsdatum');

    nameField.querySelector(labelSelector).textContent = 'Name';
    nameField.querySelector(valueSelector).append(' ' + nachnameField.querySelector(valueSelector).innerText);
    nachnameField.remove();

    plzField.querySelector(labelSelector).append(' & ' + ortField.querySelector(labelSelector).innerText);
    plzField.querySelector(valueSelector).append(' ' + ortField.querySelector(valueSelector).innerText);
    ortField.remove();

    strasseField.querySelector(labelSelector).append(' & ' + nrField.querySelector(labelSelector).innerText);
    strasseField.querySelector(valueSelector).append(' ' + nrField.querySelector(valueSelector).innerText);
    nrField.remove();

    geburtsdatum.querySelector(labelSelector).textContent = 'Geburtsdatum';
}



const setLabelsForDogData = () => {
    const anzahlTiere = getAnzahlTiere();
    
    for (let i = 0; i < anzahlTiere; i++) {        
        const geburtsdatum = document.querySelector('.tierversicherung-tier-elemente-' + i +'-geburtsdatum .econ-overview__label');
        const besitzdatum = document.querySelector('.tierversicherung-tier-elemente-' + i +'-besitzSeit .econ-overview__label');

        geburtsdatum.textContent = 'Geburtsdatum';
        besitzdatum.textContent = 'Im Besitz seit';
    }
    
}

const _sortDogData = () => {
    const anzahlTiere = getAnzahlTiere();

    setLabelsForDogData();

    for (let i = 0; i < anzahlTiere; i++) {
        const weitereAngabenBlock = document.querySelector('.tierversicherung-tier-elemente-' + i + '-besitzSeit').closest('.econ-overview__block');
        const hafAngabenBlock = document.querySelector('.tierversicherung-tier-elemente-' + i + '-ausbildung').closest('.econ-overview__block');

        weitereAngabenBlock.innerHTML = weitereAngabenBlock.innerHTML + hafAngabenBlock.innerHTML;
    }
}

export {sortTarifData, sortPartnerData, setLabelsForDogData};