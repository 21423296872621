const setClassesForPricePanel = () => {
    const panel = document.querySelector('.ue-price-panel .econ-section-content');
    const fragments = panel.querySelectorAll('.econ-fragment');

    

    fragments.forEach(fragment => {
       const childEl = fragment.firstElementChild;
       const childClasses = childEl.classList;

       if (childEl && (childClasses.length > 0)) {
        fragment.classList.add(childClasses[childClasses.length - 1]);
        childEl.classList.remove(childClasses[childClasses.length - 1]);
       }       
    });
}

export default setClassesForPricePanel;