const vermittlerText = () => 'der Uelzener Versicherung';

const _isVermittlerIframe = () => {
    const publisherIdEl = document.getElementById('publisherID');
    let isVermittler;

    (publisherIdEl.innerText !== null && publisherIdEl.innerText === 'agt') ? isVermittler = true : isVermittler = false;

    return isVermittler;
}

const _changeCommunicationHeader = () => {
    const textNode = document.querySelector('.ue-com');

    textNode.textContent = vermittlerText();
}

const _changeCommunicationInfoText = () => {
    const vermittlerInfoTextA = 'die Dokumente der Uelzener Versicherung';
    const textNodeA = document.querySelector('.ue-com__info-a');
    const textNodeB = document.querySelector('.ue-com__info-b');

    textNodeA.textContent = vermittlerInfoTextA;
    textNodeB.textContent = vermittlerText();
}

const changeVermittlerCommunicationContent = () => {
    if(_isVermittlerIframe() !== null) {
        _changeCommunicationHeader();
        _changeCommunicationInfoText();
    }
}

export default changeVermittlerCommunicationContent;