import moment from 'moment';
import 'moment/locale/de';

const _isEndOfMonth = () => {
    moment.locale('de');
    const now = moment();
    const dayOfMonth = now.date();
    const insuranceStartValue = document.querySelector('[data-econ-property="tierversicherung-versicherungsbeginn"]').value;
    const formattedStartDate = moment(insuranceStartValue.replace('.', '-'), 'DD-MM-YYYY');
    const insuranceStartDay = formattedStartDate.date();

    if (dayOfMonth > 19 && insuranceStartDay > 19) return true;
}

const showHintAtEndOfMonth = () => {
    
    const zahlintervall = document.getElementById("econZahlintervall");
    const hint = document.querySelector('.ue-doppelter-beitrag');
    
    if(zahlintervall && hint) {
        if (_isEndOfMonth() && hint.classList.contains('ue-hide') && zahlintervall.innerText === "monatlich" ) {
            hint.classList.remove('ue-hide');
        } else {
            hint.classList.add('ue-hide');
        }
    }
    
}

export default showHintAtEndOfMonth;