import { elHasListener, elIsHidden, calculateDogAge } from "./helper.js";

const _getProductBox = (product) =>  document.querySelector('.ue-product-' + product).closest('.ue-box');

const _getTarifCheckbox = (tarif) => document.querySelector('.ue-' + tarif);
const _getProductButton = (product) => document.querySelector('.ue-product-' + product);
const _getProductSelect = (tarif) => document.querySelector('.ue-' + tarif + '-tarif');

const _isKvSolo = () => {
    const crossSellingId = (document.getElementById('ue-crossselling-id')) ? document.getElementById('ue-crossselling-id').innerText : null;
    let kvSolo = (crossSellingId !== null) ? (crossSellingId === 'kv solo' || crossSellingId === 'kv cross' ) ? true : false : false;

    return kvSolo;
}

const _removeActiveTarifClass = (tarif) => {
    const activeTarif = _getProductBox(tarif);

    if (_isProductBoxActive(activeTarif)) activeTarif.classList.remove('is-active');
}

const _isProductBoxActive = (box) => {
    return box.classList.contains('is-active');
}

const _showKvPriceByTarif = (tarifId) => {
    const kvBox = document.querySelector('.ue-upselling-kv');

    if (kvBox) {
        const basisPrice = document.querySelector('#kvPriceBasis');
        const premiumPrice = document.querySelector('#kvPricePremium');
        const premiumPlusPrice = document.querySelector('#kvPricePremiumPlus');

        switch (tarifId) {
            case 'BASIS':
                if(elIsHidden(basisPrice)) basisPrice.classList.remove('ue-hide');
                if(!elIsHidden(premiumPrice)) premiumPrice.classList.add('ue-hide');
                if(!elIsHidden(premiumPlusPrice)) premiumPlusPrice.classList.add('ue-hide');
                break;
            case 'PREMIUM':
                if(!elIsHidden(basisPrice)) basisPrice.classList.add('ue-hide');
                if(elIsHidden(premiumPrice)) premiumPrice.classList.remove('ue-hide');
                if(!elIsHidden(premiumPlusPrice)) premiumPlusPrice.classList.add('ue-hide');
                break;
            case 'PREMIUM_PLUS':
                if(!elIsHidden(basisPrice)) basisPrice.classList.add('ue-hide');
                if(!elIsHidden(premiumPrice)) premiumPrice.classList.add('ue-hide');
                if(elIsHidden(premiumPlusPrice)) premiumPlusPrice.classList.remove('ue-hide');
                break;

            default:
                break;
        }
    }
}

const _setTarifId = (tarifId, activeProduct = 'op') => {
    const activeTarifSelect = _getProductSelect(activeProduct);
    const inactiveProduct = (activeProduct === 'op') ? 'kv' : 'op';
    const inactiveTarifSelect = _getProductSelect(inactiveProduct);

    switch (tarifId) {
        case 'BASIS':
            _removeActiveTarifClass('premium');
            _removeActiveTarifClass('premium-plus');
            break;
        case 'PREMIUM':
            _removeActiveTarifClass('basis');
            _removeActiveTarifClass('premium-plus');
            break;
        case 'PREMIUM_PLUS':
            _removeActiveTarifClass('basis');
            _removeActiveTarifClass('premium');
        break;
        default:
            break;
    }


    activeTarifSelect.value = tarifId;
    activeTarifSelect.dispatchEvent(new Event('change'));

    if (inactiveTarifSelect.selectedIndex !== 0) {
        inactiveTarifSelect.selectedIndex = 0;
        inactiveTarifSelect.dispatchEvent(new Event('change'));
    }

    setActiveTarifClass(activeProduct);
    setActiveTarifButtonText();
    showTarifPricesByProduct();
}

const listenForChangeOnTarifSelect = () => {
    const opSelect = _getProductSelect('op');
    const kvSelect = _getProductSelect('kv');
    const activeTarif = (opSelect.value !== '') ? opSelect.value : kvSelect.value;

    if (activeTarif !== '') _showKvPriceByTarif(activeTarif);
}

const _hasDogOlderThanSix = () => {
    const dogBirthdays = document.getElementById('ue-hundealter').innerText.split(' | ');
    let hasOldDog = false, dogAge;

    dogBirthdays.forEach(birthday => {
        dogAge = calculateDogAge(birthday);
        if (parseFloat(dogAge) >= 6) {
            hasOldDog = true;
        }
    });

    return hasOldDog;
}

const _disablePremiumPlusOpOption = () => {
    const opTarifOptions = document.querySelectorAll('[data-econ-property="tierversicherung-produkt-elemente-0-produktName"] option');

    opTarifOptions.forEach(option => {
        if (option.value === 'PREMIUM_PLUS') {
            option.disabled = true;
        }
    });
}

const _disablePremiumPlusKvOption = () => {
    const kvTarifOptions = document.querySelectorAll('[data-econ-property="tierversicherung-produkt-elemente-1-produktName"] option');

    kvTarifOptions.forEach(option => {
        if (option.value === 'PREMIUM_PLUS') {
            option.disabled = true;
        }
    });
}

const _calculateKvOnlyPrice = (opPrice, kvPrice) => {
    return Math.abs(parseFloat(kvPrice.replace(',', '.')) - parseFloat(opPrice.replace(',', '.')));
}

const setProductTitle = () => {
    const titleEl = document.querySelector('.ue-page-title');
    const opTitle = titleEl.querySelector('.ue-show-for-op');
    const kvTitle = titleEl.querySelector('.ue-show-for-kv');
    const opTitleHidden = elIsHidden(opTitle);
    const kvTitleHidden = elIsHidden(kvTitle);
    const activeProduct = getActiveProduct();

    switch (activeProduct) {
        case 'op' :
            if (opTitleHidden) opTitle.classList.remove('ue-hide');
            if(!kvTitleHidden) kvTitle.classList.add('ue-hide');
            break;
        case 'kv' :
            if (!opTitleHidden) opTitle.classList.add('ue-hide');
            if(kvTitleHidden) kvTitle.classList.remove('ue-hide');
    }
}

const showTarifPricesByProduct = () => {
    const product = getActiveProduct();
    const basisPriceOp = document.querySelector(".ue-tarif__basis .ue-box__price-sum-op");
    const basisPriceKv = document.querySelector(".ue-tarif__basis .ue-box__price-sum-kv");
    const premiumPriceOp= document.querySelector(".ue-tarif__premium .ue-box__price-sum-op");
    const premiumPriceKv = document.querySelector(".ue-tarif__premium .ue-box__price-sum-kv");
    const premiumPlusPriceOp = document.querySelector(".ue-tarif__premium-plus .ue-box__price-sum-op");
    const premiumPlusPriceKv = document.querySelector(".ue-tarif__premium-plus .ue-box__price-sum-kv");

    switch (product) {
        case "op":
            if (elIsHidden(basisPriceOp)) basisPriceOp.classList.remove('ue-hide');
            if (!elIsHidden(basisPriceKv)) basisPriceKv.classList.add('ue-hide');
            if (elIsHidden(premiumPriceOp)) premiumPriceOp.classList.remove('ue-hide');
            if (!elIsHidden(premiumPriceKv)) premiumPriceKv.classList.add('ue-hide');
            if (elIsHidden(premiumPlusPriceOp)) premiumPlusPriceOp.classList.remove('ue-hide');
            if (!elIsHidden(premiumPlusPriceKv)) premiumPlusPriceKv.classList.add('ue-hide');
            break;
        case "kv":
            if (_isKvSolo()) {
                if (!elIsHidden(basisPriceOp)) basisPriceOp.classList.add('ue-hide');
                if (elIsHidden(basisPriceKv)) basisPriceKv.classList.remove('ue-hide');
                if (!elIsHidden(premiumPriceOp)) premiumPriceOp.classList.add('ue-hide');
                if (elIsHidden(premiumPriceKv)) premiumPriceKv.classList.remove('ue-hide');
                if (!elIsHidden(premiumPlusPriceOp)) premiumPlusPriceOp.classList.add('ue-hide');
                if (elIsHidden(premiumPlusPriceKv)) premiumPlusPriceKv.classList.remove('ue-hide');
            }
            break;
    }
}

const showTarifDocsByProduct = () => {
    const curProduct = getActiveProduct();
    const opDocEls = document.querySelectorAll('.ue-tarif-info-op');
    const kvDocEls = document.querySelectorAll('.ue-tarif-info-kv');
    showTextPremiumPlusKV();

    switch (curProduct) {
        case 'op' :
            opDocEls.forEach((docEl, i) => {
               if (elIsHidden(docEl)) docEl.classList.remove('ue-hide');
               if (!elIsHidden(kvDocEls[i])) kvDocEls[i].classList.add('ue-hide');
            });
            break;
        case 'kv' :
            kvDocEls.forEach((docEl, i) => {
                if (elIsHidden(docEl)) docEl.classList.remove('ue-hide');
                if (!elIsHidden(opDocEls[i])) opDocEls[i].classList.add('ue-hide');
            });
    }
}

const disablePremiumPlusByDogAge = () => {
    const productCard = document.querySelector('.ue-tarif__premium-plus');
    const productList = productCard.querySelector('.ue-box__list');
    const productDownloadLinks = productCard.querySelectorAll('.ue-box__button-download--text');
    const productPrice = productCard.querySelector('.ue-box__price-row');
    const productButton = productCard.querySelector('.btn.ue-product-premium-plus');
    const msgOldDog = document.getElementById('msg_old_dog');
    const hasOldDog = _hasDogOlderThanSix();

    if (hasOldDog) {
        productButton.classList.add('ue-disabled');
        productButton.disabled = true;
        productButton.innerText = 'Nicht wählbar';
        _disablePremiumPlusOpOption();
        _disablePremiumPlusKvOption();
        productDownloadLinks.forEach(link => link.style.display = 'none');
        productList.classList.add('ue-hide');
        productPrice.classList.add('ue-hide');
        msgOldDog.classList.remove("ue-hide");
    }
}

const getActiveProduct = () => {
    const opGewuenscht = document.getElementById('ue-op-produkt-gewuenscht').innerText;
    const kvGewuenscht = document.getElementById('ue-kv-produkt-gewuenscht').innerText;
    let product;

    (opGewuenscht.toLowerCase() === 'ja') ? product = 'op' : (kvGewuenscht.toLowerCase() === 'ja') ? product = 'kv' : product = false;

    return product;
}

const getActiveTarif = (product = 'op') => {
    const selectedTarif = _getProductSelect(product).selectedOptions;
    let activeTarif;

    if (selectedTarif.length > 0 && selectedTarif[0].value !== '') {
        activeTarif = selectedTarif[0].value

        if (activeTarif === 'PREMIUM_PLUS') activeTarif = activeTarif.replace('_', '-');

        return activeTarif.toLowerCase();
    }
}
const choseTarif = () => {
    const basisButton = _getProductButton('basis');
    const premiumButton = _getProductButton('premium');
    const premiumPlusButton = _getProductButton('premium-plus');

    let isBasisListen =  elHasListener(basisButton), isPremiumListen = elHasListener(premiumButton), isPremiumPlusListen = elHasListener(premiumPlusButton);

    if(!isBasisListen) {
        basisButton.classList.add('is-listen');
        basisButton.addEventListener('click', (ev) => {
            ev.preventDefault();
            _setTarifId('BASIS', getActiveProduct());
        });
    }

    if(!isPremiumListen) {
        premiumButton.classList.add('is-listen');
        premiumButton.addEventListener('click', (ev) => {
            ev.preventDefault();
            _setTarifId('PREMIUM', getActiveProduct());
        });
    }

    if(!isPremiumPlusListen) {
        premiumPlusButton.classList.add('is-listen');
        premiumPlusButton.addEventListener('click', (ev) => {
            ev.preventDefault();
            _setTarifId('PREMIUM_PLUS', getActiveProduct());
        });
    }
}

const showKvOnlyPrice = () => {
    const opPrices = document.getElementById('ue-op-preise').innerText.split(' | ');
    const kvPrices = document.getElementById('ue-kv-preise').innerText.split(' | ');
    const kvBox = document.querySelector('.ue-upselling-kv');

    if (kvBox) {
        kvPrices.forEach((kvPrice, i) => {
            let priceEl = document.createDocumentFragment();

            switch (i) {
                case 0:
                    priceEl = document.getElementById('kvPriceBasis');
                    break;
                case 1:
                    priceEl = document.getElementById('kvPricePremium');
                    break;
                case 2:
                    priceEl = document.getElementById('kvPricePremiumPlus');
                default:
                    break;
            }

            priceEl.innerText = '+ ' + _calculateKvOnlyPrice(opPrices[i], kvPrice).toFixed(2).replace('.', ',') + ' €';
        });
    }
}

const choseKvUpselling = () => {
    const upsellingCheckbox = document.querySelector('[data-econ-property="tierversicherung-produkt-elemente-1-gewuenscht"]');
    
    if (upsellingCheckbox) {
        let isListen = elHasListener(upsellingCheckbox);

        if (!isListen) {
            upsellingCheckbox.classList.add('is-listen');
            upsellingCheckbox.addEventListener('change', (ev) => {
                let activeTarif = getActiveTarif(getActiveProduct());

                if (activeTarif === 'premium-plus') {
                    activeTarif = activeTarif.replace('-', '_');
                }

                if(activeTarif !== undefined) {
                    if (upsellingCheckbox.checked) {
                        _setTarifId(activeTarif.toUpperCase(), 'kv');
                    } else {
                        _setTarifId(activeTarif.toUpperCase(), 'op');
                    }
                }

            });
        }
    }
}

const showTextPremiumPlusKV = () => {
    let basisPremiumElement = document.querySelector('.basis-premium');
    let premiumPlusElement = document.querySelector('.premium-plus');
    let activeTarif = getActiveTarif(getActiveProduct());

    //prüfe ob Elemente existieren
    if (basisPremiumElement && premiumPlusElement) {
        //PremiumPlus gewählt? dann anzeigen
        if (activeTarif === "premium-plus") {
            premiumPlusElement.style.display = 'grid';
            basisPremiumElement.style.display = 'none';
        } else {
            premiumPlusElement.style.display = 'none';
            basisPremiumElement.style.display = 'grid';
        }
    }
}

const setActiveTarifClass = (product) => {
    const activeTarif = getActiveTarif(product);
    const activeTarifBox = _getProductBox(activeTarif);

    if (!_isProductBoxActive(activeTarifBox)) {
        activeTarifBox.classList.add('is-active')
    }
}

const setActiveTarifButtonText = () => {
    const activeTarif = getActiveTarif(getActiveProduct());
    const basisButton = _getProductButton('basis');
    const premiumButton = _getProductButton('premium');
    const premiumPlusButton = _getProductButton('premium-plus');

    switch (activeTarif) {
        case 'basis':
            premiumButton.textContent = 'Angebot auswählen';
            premiumPlusButton.textContent = 'Angebot auswählen';
            basisButton.textContent = 'Ausgewählt';
            break;
        case 'premium':
            basisButton.textContent = 'Angebot auswählen';
            premiumPlusButton.textContent = 'Angebot auswählen';
            premiumButton.textContent = 'Ausgewählt';
            break;
        case 'premium-plus':
            basisButton.textContent = 'Angebot auswählen';
            premiumButton.textContent = 'Angebot auswählen';
            premiumPlusButton.textContent = 'Ausgewählt';
        break;      
        default:
            break;
    }
}

export { choseTarif, showKvOnlyPrice, listenForChangeOnTarifSelect, choseKvUpselling, getActiveProduct, getActiveTarif, setProductTitle, showTarifPricesByProduct, showTarifDocsByProduct, setActiveTarifClass, setActiveTarifButtonText, disablePremiumPlusByDogAge};