const _activateErrorFeedBack = (error) => {
    const parentFragment = error.closest('.econ-fragment');
    const feedBackEl = parentFragment.querySelector('.econ-tooltip--feedback .econ-tooltip');

    feedBackEl.removeAttribute('style');
}

const checkForErrors = () => {
    const errors = document.querySelectorAll('.econ-controls .has-error');

    if (errors.length > 0 ){
        errors.forEach((error, index) => {
            _activateErrorFeedBack(error);
        });
    }

    return;
}

export default checkForErrors;