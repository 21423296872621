const _isGewerblicheNutzung = () => {
    const gewerblichList = document.querySelectorAll('#econGewerblicheNutzung li');
    let count = 0;

    gewerblichList.forEach(isGewerblich => {
        if (isGewerblich.innerText === 'ja') count++
    });
    //console.log(count);
    if (count > 0) {
        return true;
    } else {
        return false;
    }
}

const setActiveClassOnRadios = () => {
    const radios = document.querySelectorAll('input[type="radio"]');

    radios.forEach(radio => {
        radio.addEventListener("change", () => {
            const radioEl = radio.closest(".radio");
            let radioEconProperty = radio.dataset.econProperty;
            let radioGroup = document.querySelectorAll("[data-econ-property=" + radioEconProperty + "]");

            radioGroup.forEach(radio => {
                if (radio.closest(".radio").classList.contains("is-active")) radio.closest(".radio").classList.remove("is-active");
            });


            if (radio.checked && !radioEl.classList.contains("is-active")) {
                radioEl.classList.add("is-active");
            }
        });
    });
}

const enableBausteinBox = () => {
    const bausteine = document.querySelector('.ue-bausteine');
    const bausteineAreDiabled = bausteine.classList.contains('ue-disabled');

    if (bausteineAreDiabled) bausteine.classList.remove('ue-disabled');
}

const setSelectInitLabelClass = () => {
    const slimSelectFields = document.querySelectorAll('.econ-form-control__select select');

    slimSelectFields.forEach((select) => {
        const isSelected = (select.selectedOptions.length > 0);
        const isPlaceholder = (isSelected) ? (select[select.selectedIndex].value.trim().length === 0) ? true : false : true;
        
        if (!isPlaceholder) {
            select.parentElement.classList.add('is-selected');
            select.nextElementSibling.firstChild.classList.add('is-selected');
        }
    });
    
}

const setBodyClassByPageContent = () => {
    const pagePrefix = 'ue-kunden-rechner__'
    const pages = ['tarifdaten', 'tarifauswahl', 'hundedaten', 'partnerdaten', 'crossselling--1', 'crossselling--2', 'zusammenfassung', 'danke'];
    let formClass, hasForm;

    pages.forEach( page => {
        formClass = pagePrefix+page;
        hasForm = document.querySelector('.' + formClass);

        if(hasForm) {
            document.body.classList.add(page);           
        }  else {
            document.body.classList.remove(page);
        }
    });
    
}

const getPage = () => {
    const pagePrefix = 'ue-kunden-rechner__'
    const page = document.querySelector('form').classList[0].replace(pagePrefix, '');

    return page;
}

export { getPage, setActiveClassOnRadios, setSelectInitLabelClass, setBodyClassByPageContent };