const scopeHund = {
  antrag: {
    gattung: "Hund", anzahlTiere: 1, antragPreis: 0, antragId: "", processType: "",
  },
  produkt: {
    op: { gewuenscht: "nein", deckung: 0, preis: 0 },
    kv: { gewuenscht: "nein", deckung: 0, preis: 0 },
    hp: { gewuenscht: "nein", preis: 0 },
    hs: { gewuenscht: "nein", preis: 0 },
  },
  baustein: {
    bestLeistung: { gewuenscht: "nein", preis: 0 }
  }
};

const scopeKatze = {
  antrag: {
    gattung: "Katze", anzahlTiere: 1, antragPreis: 0, antragId: ""
  },
  produkt: {
    op: { gewuenscht: "nein", preis: 0 },
    kv: { gewuenscht: "nein", preis: 0 }
  },
  baustein: {
    gesundheitPlus: { gewuenscht: "nein", preisOP: 0, preisKV: 0 }
  }
};





//dataLayerEvent bei Seitenwechsel
function setPageEvent(eventTitle) {
  //dataLayer initialisieren
  window.dataLayer = window.dataLayer || [];

  //Suche Elemente im dataLayer
  let eventElement = _findEvent(eventTitle, dataLayer);

  //hole item_name aus Prozess oder dataLayer
  let lastItemName = _getItemName();

  //Event eintragen, wenn es keinen Eintrag im dataLayer gibt
  if (eventElement == false) {
    var myAnalyticsInfo = {
      'event': eventTitle,
      'item_name': lastItemName
    };
    // DataLayer push
    dataLayer.push(myAnalyticsInfo);

    //Hauptprodukt geändert? ChangeEvent eintragen
    _setChangeEvent();
  }
}


//dataLayerEvent add_to_cart Katze
function addToCartEventKatze() {
  //Katzen Scope mit Werte füllen
  _fillScopeKatze();

  //DataLayer init 
  window.dataLayer = window.dataLayer || [];

  //Suche Elemente im dataLayer
  let eventElement = _findEvent('add_to_cart', dataLayer);

  let myAnalyticsInfo = {
    'event': 'add_to_cart',
    'products': []
  };

  //fortlaufende ID
  let id = 0;

  //Antrag
  let category = scopeKatze.antrag.gattung;
  let anzahlTiere = scopeKatze.antrag.anzahlTiere;
  //Produkte
  let kvProduktName = scopeKatze.produkt.kv.gewuenscht;
  let gesundheitPlusBaustein = scopeKatze.baustein.gesundheitPlus.gewuenscht;
  //Preise
  let gesundheitPlusBausteinPreisOP = scopeKatze.baustein.gesundheitPlus.preisOP;
  let gesundheitPlusBausteinPreisKV = scopeKatze.baustein.gesundheitPlus.preisKV;

  //Produkt 2: Gesundheit Plus Baustein
  if (gesundheitPlusBaustein.toLowerCase() === 'ja') {
    let bausteinPreis = (kvProduktName.toLowerCase() === 'ja') ? gesundheitPlusBausteinPreisKV : gesundheitPlusBausteinPreisOP;
    let baustein = _createProductJSON("Gesundheit_Plus_Baustein", category, "K-GSPB-PLUS", bausteinPreis, anzahlTiere, id);
    myAnalyticsInfo.products.push(baustein);
    id++;
  }

  //Event eintragen, wenn es keinen Eintrag im dataLayer gibt
  if (eventElement == false) {
    //mind. ein Artikel, dann eintragen
    if (id >= 1) {
      // DataLayer push
      dataLayer.push(myAnalyticsInfo);
    }
  }
}


//dataLayerEvent für Zusatzbaustein Hund
function purchaseEventKatze() {
  //Katzen Scope mit Werte füllen
  _fillScopeKatze();

  //Antrag
  let category = scopeKatze.antrag.gattung;
  let anzahlTiere = scopeKatze.antrag.anzahlTiere;
  let antragPreis = _formatPrice(scopeKatze.antrag.antragPreis);
  let antragId = scopeKatze.antrag.antragId;

  //Produkte
  let opProduktName = scopeKatze.produkt.op.gewuenscht;
  let kvProduktName = scopeKatze.produkt.kv.gewuenscht;

  //Zusatzbausteine
  let gesundheitPlusBaustein = scopeKatze.baustein.gesundheitPlus.gewuenscht;

  //Preise
  let opProduktPreise = scopeKatze.produkt.op.preis;
  let kvProduktPreise = scopeKatze.produkt.kv.preis;
  let gesundheitPlusBausteinPreisOP = scopeKatze.baustein.gesundheitPlus.preisOP;
  let gesundheitPlusBausteinPreisKV = scopeKatze.baustein.gesundheitPlus.preisKV;

  let myAnalyticsInfo = {
    'event': 'purchase',
    'transactionId': antragId,
    'transactionTotal': parseFloat(antragPreis),
    'transactionProducts': []
  };

  //fortlaufende ID
  let id = 1;

  //Produkt 1a: OP-Versicherung 
  if (opProduktName.toLowerCase() === 'ja' && kvProduktName.toLowerCase() !== 'ja') {
    let opProduct = _createProductJSON("Katzen-OP", category, "KATZEN_OP", _getSoloPrice(opProduktPreise, "basis"), anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(opProduct);
    id++;
  }

  //Produkt 1b: KV-Versicherung 
  if (kvProduktName.toLowerCase() === 'ja') {
    let kvProduct = _createProductJSON("Katzen-KV", category, "KATZEN_KV", _getSoloPrice(kvProduktPreise, "basis"), anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(kvProduct);
    id++;
  }

  //Produkt 2: Gesundheit Plus Baustein
  if (gesundheitPlusBaustein.toLowerCase() === 'ja') {
    let bausteinPreis = (kvProduktName.toLowerCase() === 'ja') ? gesundheitPlusBausteinPreisKV : gesundheitPlusBausteinPreisOP;
    let baustein = _createProductJSON("Gesundheit_Plus_Baustein", category, "K-GSPB-PLUS", bausteinPreis, anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(baustein);
    id++;
  }

  // DataLayer push
  window.dataLayer = window.dataLayer || [];
  dataLayer.push(myAnalyticsInfo);
}



//dataLayerEvent add_to_cart Hund
function addToCartEventHund(eventTitle = 'add_to_cart') {
  //Hunde-Scope mit Werten füllen
  _fillScopeHund();

  //DataLayer init 
  window.dataLayer = window.dataLayer || [];

  //Suche Elemente im dataLayer
  let eventElement = _findEvent(eventTitle, dataLayer);

  let myAnalyticsInfo = {
    'event': eventTitle,
    'products': []
  };

  //fortlaufende ID
  let id = 0;

  //Haftpflicht Produkt
  if (scopeHund.produkt.hp.gewuenscht.toLowerCase() === 'ja') {
    id++;
    let hpProduct = _createProductJSON("HUNDE_HP_2024", "Hund", "HUNDE_HAFTPFLICHT", scopeHund.produkt.hp.preis, scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.products.push(hpProduct);

  }

  //Best Leistung Baustein
  if (scopeHund.baustein.bestLeistung.gewuenscht.toLowerCase() === 'ja') {
    id++;
    let product = _createProductJSON("HUNDE_HP_2024_BAUSTEIN", "Hund", "H-BLB-01", scopeHund.baustein.bestLeistung.preis, scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.products.push(product);
  }

  //Produkt 1a: OP-Versicherung 
  if (scopeHund.produkt.op.gewuenscht.toLowerCase() === 'ja' && scopeHund.produkt.op.deckung != "") {
    id++;
    let product = _createProductJSON("Hunde-OP", "Hund", "HUNDE_OP_SCHUTZ", _getSoloPrice(scopeHund.produkt.op.preis, scopeHund.produkt.op.deckung), scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.products.push(product);
    
  }

  //Halterschutz Produkt
  if (scopeHund.produkt.hs.gewuenscht.toLowerCase() === 'ja') {
    id++;
    let hsProduct = _createProductJSON("Hunde-RSV", "Hund", "HUNDE_HALTERSCHUTZ", scopeHund.produkt.hs.preis, scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.products.push(hsProduct);
  }


  //Event eintragen, wenn es keinen Eintrag im dataLayer gibt
  if (eventElement == false) {
    //mind. ein Artikel, dann eintragen
    if (id >= 1) {
      // DataLayer push
      dataLayer.push(myAnalyticsInfo);
    }
  }

}

//dataLayerEvent Bestellabschluss Hund
function purchaseEventHund() {

  //Hunde-Scope mit Werten füllen
  _fillScopeHund();

  let myAnalyticsInfo = {
    'event': 'purchase',
    'transactionId': scopeHund.antrag.antragId,
    'transactionTotal': parseFloat(_formatPrice(scopeHund.antrag.antragPreis)),
    'transactionProducts': []
  };

  //fortlaufende ID
  let id = 1;

  //Produkt 1a: OP-Versicherung 
  if (scopeHund.produkt.op.gewuenscht.toLowerCase() === 'ja' && scopeHund.produkt.kv.gewuenscht.toLowerCase() !== 'ja') {
    let opProduct = _createProductJSON("Hunde-OP", "Hund", "HUNDE_OP_SCHUTZ", _getSoloPrice(scopeHund.produkt.op.preis, scopeHund.produkt.op.deckung), scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(opProduct);
    id++;
  }

  //Produkt 1b: KV-Versicherung 
  if (scopeHund.produkt.kv.gewuenscht.toLowerCase() === 'ja') {
    let kvProduct = _createProductJSON("Hunde-KV", "Hund", "HUNDE_KV", _getSoloPrice(scopeHund.produkt.kv.preis, scopeHund.produkt.kv.deckung), scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(kvProduct);
    id++;
  }

  //Produkt 2: Haftpflicht 
  if (scopeHund.produkt.hp.gewuenscht.toLowerCase() === 'ja') {
    let hpProduct = _createProductJSON("HUNDE_HP_2024", "Hund", "HUNDE_HAFTPFLICHT", scopeHund.produkt.hp.preis, scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(hpProduct);
    id++;
  }

  //Produkt 2: Haftpflicht Baustein Best-Leistung  
  if (scopeHund.produkt.hp.gewuenscht.toLowerCase() === 'ja' && scopeHund.baustein.bestLeistung.gewuenscht.toLowerCase() === 'ja') {
    let hpProduct = _createProductJSON("HUNDE_HP_2024_BAUSTEIN", "Hund", "H-BLB-01", scopeHund.baustein.bestLeistung.preis, scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(hpProduct);
    id++;
  }

  //Produkt 3: Halterschutz
  if (scopeHund.produkt.hs.gewuenscht.toLowerCase() === 'ja') {
    let hsProduct = _createProductJSON("Hunde-RSV", "Hund", "HUNDE_HALTERSCHUTZ", scopeHund.produkt.hs.preis, scopeHund.antrag.anzahlTiere, id);
    myAnalyticsInfo.transactionProducts.push(hsProduct);
    id++;
  }

  // DataLayer push
  window.dataLayer = window.dataLayer || [];
  dataLayer.push(myAnalyticsInfo);
}


//Suche nach Element im Datalayer
function _findEvent(eventName, dataLayer) {
  let hasEvent = false;
  let search = dataLayer.map(object => object.event).indexOf(eventName);
  if (search !== -1) {
    hasEvent = true;
  } else {
    hasEvent = false;
  }
  return hasEvent;
}

//Erzeugt Produkt JSON Objekt für dataLayer
function _createProductJSON(productName, category, sku, productPrice, quantity, id) {
  let productObj = {
    'id': id,
    'sku': sku,
    'name': productName,
    'category': category,
    'price': parseFloat(_formatPrice(productPrice)),
    'quantity': quantity
  };
  return productObj;
}

//Erzeugt bei Produktwechsel ein Change Event im DataLayer
function _setChangeEvent() {
  eventTitle = 'changeEvent_upselling_kv';
  startProduct = "";
  currentProduct = _getItemName();

  //dataLayer initialisieren
  window.dataLayer = window.dataLayer || [];

  //Einstiegs-Produktname aus Event "completed_start" auslesen
  var completedStartObject = dataLayer.find(function (obj) {
    return obj.event === "completed_start";
  });
  if (completedStartObject) {
    startProduct = completedStartObject.item_name
  }

  //Produkt hat sich geändert? dann Change Event eintragen ...
  if (startProduct != currentProduct) {
    //Suche Change-Element im dataLayer
    let eventElement = _findEvent(eventTitle, dataLayer);

    //kein Change-Event gefunden? Dann Change Event eintragen
    if (eventElement == false) {
      var myAnalyticsInfo = {
        'event': eventTitle
      };
      // DataLayer push
      dataLayer.push(myAnalyticsInfo);
    }
  }
}

//Erzeugt ItemName bei boolesche Produktwerte
function _getItemName() {
  let item_name = "";
  let econHauptprodukt = document.getElementById("econHauptprodukt");

  //1. Produktnamen aus dem Prozess lesen
  if (econHauptprodukt) {
    item_name = _isKvOp(econHauptprodukt.innerText);
  }
  //2. Produktnamen aus dem DataLayer lesen 
  else if (dataLayer.length > 0) {
    item_name = dataLayer[dataLayer.length - 1].item_name;
  }
  //3 Kein Produktnamen gefunden
  else {
    item_name = "kein Produkt gefunden";
  }

  return item_name;
}

//Wert Ja = KV, Nein = OP, sonst Produktname
function _isKvOp(value) {
  let item_name = value;
  if (value.toLowerCase() === 'ja') {
    item_name = "Krankenversicherung"
  } else if (value.toLowerCase() === 'nein') {
    item_name = "OP-Versicherung"
  }
  return item_name;
}

//Produkte mit Deckung: Gibt richtigen Preis zurück
function _getSoloPrice(productPrice, deckung) {
  const arrayProductPrice = productPrice.split('|');
  switch (deckung) {
    case "basis":
      productPrice = arrayProductPrice[0];
      break;
    case "premium":
      productPrice = arrayProductPrice[1];
      break;
    case "premium plus":
      productPrice = arrayProductPrice[2];
  }
  return productPrice;
}


//Katzen-Scope mit Werten aus dem Prozess füllen
function _fillScopeKatze() {
  //Antrag
  scopeKatze.antrag.anzahlTiere = _getValueFromElement("anzahl_tiere");
  scopeKatze.antrag.antragPreis = _getValueFromElement("antrag_preis");
  scopeKatze.antrag.antragId = _getValueFromElement("antrag_id");
  //OP-Produkt
  scopeKatze.produkt.op.gewuenscht = _getValueFromElement("opGewuenscht");
  scopeKatze.produkt.op.preis = _getValueFromElement("opProduktPreis");
  //KV-Produkt
  scopeKatze.produkt.kv.gewuenscht = _getValueFromElement("kvGewuenscht");
  scopeKatze.produkt.kv.preis = _getValueFromElement("kvProduktPreis");
  //GesundheitPlus Baustein
  scopeKatze.baustein.gesundheitPlus.gewuenscht = _getValueFromElement("gesundheitPlusBaustein");
  scopeKatze.baustein.gesundheitPlus.preisOP = _getValueFromElement("gesundheitPlusBausteinPreisOP");
  scopeKatze.baustein.gesundheitPlus.preisKV = _getValueFromElement("gesundheitPlusBausteinPreisKV");
}


//Hunde-Scope mit Werten aus dem Prozess füllen
function _fillScopeHund() {
  //Antrag
  scopeHund.antrag.anzahlTiere = _getValueFromElement("anzahl_tiere");
  scopeHund.antrag.antragPreis = _getValueFromElement("antrag_preis");
  scopeHund.antrag.antragId = _getValueFromElement("antrag_id");
  scopeHund.antrag.processType = _getValueFromElement("crossSellingId");
  //OP-Produkt
  scopeHund.produkt.op.gewuenscht = _getValueFromElement("opGewuenscht");
  scopeHund.produkt.op.deckung = _getValueFromElement("opDeckung");
  scopeHund.produkt.op.preis = _getValueFromElement("opProduktPreis");
  //KV-Produkt
  scopeHund.produkt.kv.gewuenscht = _getValueFromElement("kvGewuenscht");
  scopeHund.produkt.kv.deckung = _getValueFromElement("kvDeckung");
  scopeHund.produkt.kv.preis = _getValueFromElement("kvProduktPreis");
  //HP-Produkt
  scopeHund.produkt.hp.gewuenscht = _getValueFromElement("hpGewuenscht");
  scopeHund.produkt.hp.preis = _getValueFromElement("hpProduktPreis");
  //HS-Produkt
  scopeHund.produkt.hs.gewuenscht = _getValueFromElement("hsGewuenscht");
  scopeHund.produkt.hs.preis = _getValueFromElement("hsProduktPreis");
  //BestLeistung Baustein
  scopeHund.baustein.bestLeistung.gewuenscht = _getValueFromElement("bestLeistungGewuenscht");
  scopeHund.baustein.bestLeistung.preis = _getValueFromElement("bestLeistungPreis");
}

//prüft ob Element in Prozess existiert
function _getValueFromElement(id) {
  let element = document.getElementById(id);
  if (element) {
    return element.innerText;
  } else {
    return "";
  }
}

//prüft ob Prozess mit Crosselling aufgerufen wird
function isCrossellingProcess() {
  _fillScopeHund();
  return scopeHund.antrag.processType.includes("cross");
}


function _formatPrice(price) {
  formatPrice = price.replace(".", "").replace(",", ".");
  return formatPrice;
}


export { setPageEvent, purchaseEventKatze, purchaseEventHund, addToCartEventKatze, addToCartEventHund, isCrossellingProcess };

