"use strict";

// base modules
import { setModalParentClass, triggerClickOnModalButton, triggerClickOnCloseButton } from "./modules/base/modal.js";
import changeVermittlerCommunicationContent from "./modules/base/iframeOverrides.js";
import setClassesForPricePanel from "./modules/base/pricePanel.js";
import checkForErrors from "./modules/base/errors.js";
import { setPageEvent, purchaseEventHund, addToCartEventHund, isCrossellingProcess } from "./modules/base/tracking.js";
import showHintAtEndOfMonth from "./modules/base/doppelterBeitrag.js";

//hund opkv modules
import buildStructureForStyling from "./modules/hund/opkv/stylingStructure.js";
import initAccordion from "./modules/hund/opkv/accordion.js";
import { triggerClickOnInfoIcon } from "./modules/hund/opkv/infoIcons.js";
import { calculateBausteinPrices, checkBausteine } from "./modules/hund/opkv/bausteine.js";
import { choseTarif, showKvOnlyPrice, listenForChangeOnTarifSelect, choseKvUpselling, getActiveTarif, getActiveProduct, setProductTitle, showTarifPricesByProduct, showTarifDocsByProduct, setActiveTarifClass, setActiveTarifButtonText, disablePremiumPlusByDogAge } from "./modules/hund/opkv/product.js";
import { initProgressBar, setBreadcrumbCounter, removeProgressBarWidth, initProgressBarByAddRemoveButton, changeProgressbarWidthOnInput } from "./modules/hund/opkv/progressBar.js";
import { buildProductSlider } from "./modules/hund/opkv/slider.js";
import { initSlimselectOnStart, initSlimSelectOnTitle, initSlimSelectOnBestandskunde } from "./modules/hund/opkv/slimSelect.js";
import { sortTarifData, sortPartnerData, setLabelsForDogData } from "./modules/hund/opkv/overview.js";
import { getPage, setSelectInitLabelClass, disableBaustein } from "./modules/hund/opkv/states.js";
import { checkBestLeistung } from "./modules/hund/opkv/bestLeistung.js";


document.addEventListener("DOMContentLoaded", () => {
    buildStructureForStyling();
    triggerClickOnInfoIcon();

    let page = getPage();

    switch (page) {
        case 'tarifdaten':
            setProductTitle();
            initSlimselectOnStart();
            setSelectInitLabelClass();
            initProgressBar();
            initProgressBarByAddRemoveButton();
            changeProgressbarWidthOnInput();
            break;

        case 'tarifauswahl':
            removeProgressBarWidth();
            setBreadcrumbCounter();
            initSlimSelectOnBestandskunde();
            showTarifPricesByProduct();
            showKvOnlyPrice();
            listenForChangeOnTarifSelect();
            disablePremiumPlusByDogAge();
            buildProductSlider();
            showTarifDocsByProduct();
            setModalParentClass();
            triggerClickOnModalButton();
            triggerClickOnCloseButton();
            setClassesForPricePanel();
            initAccordion();
            calculateBausteinPrices();
            choseTarif();
            choseKvUpselling();
            checkBausteine();
            break;

        case 'partnerdaten':
            setBreadcrumbCounter();
            initSlimSelectOnTitle();
            setSelectInitLabelClass();
            setClassesForPricePanel();
            initAccordion();
            changeVermittlerCommunicationContent();
            showHintAtEndOfMonth();
            break;

        case 'crossselling--1':
            setBreadcrumbCounter();
            checkBestLeistung();
            setClassesForPricePanel();
            initAccordion();
            break;

        case 'crossselling--3':
            setBreadcrumbCounter();
            setClassesForPricePanel();
            initAccordion();
            break;

        case 'zusammenfassung':
            setBreadcrumbCounter();
            sortTarifData();
            sortPartnerData();
            setLabelsForDogData();
            break;

        default:
            break;
    }
});

Wicket.Event.subscribe("/ajax/call/success", function (jqEvent, attributes, jqXHR, errorThrown, textStatus) {

    const page = getPage();

    checkForErrors();
    buildStructureForStyling();
    triggerClickOnInfoIcon();

    switch (page) {
        case 'tarifdaten':
            initProgressBar();
            setProductTitle();
            initSlimselectOnStart();
            setSelectInitLabelClass();
            initProgressBarByAddRemoveButton();
            changeProgressbarWidthOnInput();
            break;

        case 'tarifauswahl':
            initSlimSelectOnBestandskunde();
            buildProductSlider();
            removeProgressBarWidth();
            disablePremiumPlusByDogAge();
            showTarifPricesByProduct();
            showKvOnlyPrice();
            showTarifDocsByProduct();
            choseTarif();
            listenForChangeOnTarifSelect();
            choseKvUpselling();
            checkBausteine();
            setModalParentClass();
            triggerClickOnModalButton();
            triggerClickOnCloseButton();
            setClassesForPricePanel();

            if (attributes.mp || (attributes.e[0] === 'click' && !attributes.pd)) {
                setBreadcrumbCounter();
                calculateBausteinPrices();
                setPageEvent("completed_start");
            }
            break;

        case 'hundedaten':
            if (attributes.mp || (attributes.e[0] === 'click' && !attributes.pd)) {
                setBreadcrumbCounter();
                setPageEvent("completed_tarif");
            }
            break;

        case 'partnerdaten':
            showHintAtEndOfMonth();
            initSlimSelectOnTitle();
            setSelectInitLabelClass();
            changeVermittlerCommunicationContent();
            setClassesForPricePanel();

            if (attributes.mp || (attributes.e[0] === 'click' && !attributes.pd)) {
                setBreadcrumbCounter();
                setPageEvent("completed_tier");
            }
            break;

        case 'crossselling--1':
            setClassesForPricePanel();
            if (attributes.mp || (attributes.e[0] === 'click' && !attributes.pd)) {
                setBreadcrumbCounter();
                setPageEvent("completed_halter");
            }
            checkBestLeistung();
            break;

        case 'crossselling--2':

            if (attributes.mp || (attributes.e[0] === 'click' && !attributes.pd)) {
                setBreadcrumbCounter();
            }
            break;

        case 'crossselling--3':
            setClassesForPricePanel();

            if (attributes.mp || (attributes.e[0] === 'click' && !attributes.pd)) {
                setBreadcrumbCounter();
                setPageEvent("completed_crossselling1");
            }
            break;

        case 'zusammenfassung':
            if (attributes.mp) {
                setBreadcrumbCounter();
                sortTarifData();
                sortPartnerData();
                setLabelsForDogData();
            }

            if (attributes.mp || (attributes.e[0] === 'click' && !attributes.pd)) {
                if (isCrossellingProcess()) {
                    setPageEvent("completed_crossselling2");
                    addToCartEventHund();
                } else {
                    setPageEvent("completed_halter");
                }
            }

            break;

        case 'danke':
            if (attributes.mp) {
                setBreadcrumbCounter();
                setPageEvent("completed_uebersicht");
                purchaseEventHund();
            }
            break;

        default:
            break;
    }

    console.log('success', page, jqEvent, attributes, jqXHR, errorThrown, textStatus);
});

// Wicket.Event.subscribe("/ajax/call/after", function(jqEvent, attributes, jqXHR, errorThrown, textStatus) {
//     console.log('after', jqEvent, attributes, jqXHR);
// });
// Wicket.Event.subscribe("/ajax/call/failure", function(jqEvent, attributes, jqXHR, errorThrown, textStatus) {
//     console.log('failure', errorThrown);
// });
// Wicket.Event.subscribe("/ajax/call/complete", function(jqEvent, attributes, jqXHR, errorThrown, textStatus) {
//     console.log('complete', jqEvent, attributes, jqXHR);
// });
// Wicket.Event.subscribe("/ajax/call/done", function(jqEvent, attributes, jqXHR, errorThrown, textStatus) {
//     console.log('done', jqEvent, attributes, jqXHR);
// });
// Wicket.Event.subscribe("/dom/node/removing", function(jqEvent, attributes, jqXHR, errorThrown, textStatus) {
//     console.log('dom node removing', jqEvent, attributes, jqXHR, errorThrown, textStatus);
// });
// Wicket.Event.subscribe("/dom/node/added", function(jqEvent, attributes, jqXHR, errorThrown, textStatus) {
//
//     //console.log('dom node added');
//
//     if(attributes.hasChildNodes() && attributes.firstElementChild != null) {
//         //console.log(attributes.firstElementChild, typeof attributes.firstElementChild, attributes.firstChild, typeof attributes.firstChild);
//         const hasClassAttr = attributes.firstElementChild.hasAttribute('class');
//
//         if (hasClassAttr) {
//             const isUpselling = attributes.firstElementChild.classList.contains('ue-upselling-kv');
//             if (isUpselling) choseKvUpselling();
//         }
//     }
// });