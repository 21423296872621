import { elHasListener } from './helper.js';
const _setAccordionAttributes = (triggerEl, contentEl) => {
    const contentIsOpened = (triggerEl.getAttribute('aria-expanded') === 'true') ? true : false;
//console.log(contentIsOpened);
    if (contentIsOpened) {
        triggerEl.setAttribute('aria-expanded', 'false');
        contentEl.setAttribute('aria-hidden', 'true');
    } else {
        triggerEl.setAttribute('aria-expanded', 'true');
        contentEl.setAttribute('aria-hidden', 'false');
    }
}

const _toggleAccordionContent = (accEl, trigger) => {
    const contentId = trigger.attributes['aria-controls'].value;
    accEl.id = contentId;
//console.log(elHasListener(trigger));
    if (!elHasListener(trigger)) {
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            if (!accEl) return;
            trigger.classList.add(('is-listen'));
            _setAccordionAttributes(trigger, accEl);
        });
    }
}

const initAccordion = () => {
    const trigger = document.querySelector('.ue-accordion-trigger');
    const content = document.querySelector('.ue-price-panel__rabatte-content');

    if (trigger !== null && content !== null) {
        content.setAttribute('aria-hidden', 'true');
        trigger.setAttribute('aria-expanded', 'false');
        _toggleAccordionContent(content, trigger);
    }
}

export default initAccordion;