const _getPreviousSibling = (elem, selector) => {

	// Get the next sibling element
	let sibling = elem.previousElementSibling;
  
	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.previousElementSibling;
	}

};

const _getNextSibling = (elem, selector) => {

	// Get the next sibling element
	let sibling = elem.nextElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling
	}

};

const _toggleInfoDisplay = (evt, infoEl) => {
    if (window.getComputedStyle(infoEl).display === "none") {
        infoEl.removeAttribute('style');
    } else {
        infoEl.style.display = 'none';
    }
}

const _isListenOnInfoIcon = (icon) => icon.classList.contains('listen');

const triggerClickOnInfoIcon = () => {
    const icons = document.querySelectorAll('.box .ue-field-headline .econ-control-icon');
    let headlineField, tooltipField, tooltipFieldhasText, tooltipFieldId, tooltip;
    
    icons.forEach((icon) => {
        
        if (!_isListenOnInfoIcon(icon)) {
            icon.addEventListener('click', (evt) => {
                headlineField = icon.closest('.econ-fragment');
                tooltipField = _getNextSibling(headlineField, '.econ-fragment.form-horizontal').querySelector('.econ-tooltip');
                tooltipFieldhasText = tooltipField.querySelector('p');
                tooltipFieldId = (tooltipFieldhasText != null) ? tooltipField.id : _getNextSibling(tooltipField.parentElement.parentElement.parentElement, '.econ-fragment.form-horizontal').querySelector('.econ-tooltip').id;
                tooltip = document.getElementById(tooltipFieldId);
                _toggleInfoDisplay(evt, tooltip);
            });
            icon.classList.add('listen');
        }
    });
}

const moveInfoIconsToHeadline = () => {
    const infoIcons = document.querySelectorAll('.box .econ-section-content .form-horizontal .econ-control-icon');
    let formfield = '';
    let headlineField = '';
    let headline = '';
    let hasInfo = false;

    infoIcons.forEach((info, index) => {
        hasInfo = info.nextElementSibling.querySelector('.econ-tooltip__inner p');

        if (hasInfo) {
            formfield = info.closest('.econ-fragment');
            headlineField = _getPreviousSibling(formfield, '.econ-fragment:not(.form-horizontal)');
            headline = headlineField.querySelector('.ue-field-headline h4');
            if(headline != undefined) headline.after(info);
        } 
    });
}

export { moveInfoIconsToHeadline, triggerClickOnInfoIcon };