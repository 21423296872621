import moment from 'moment';
import 'moment/locale/de';

const disableChosenStyles = () => {
    const searchRegEx = /chosen.*/;

    for (var i = 0; i < document.styleSheets.length; i++) {
        if (document.styleSheets[i].href.search(searchRegEx) != -1) {
            document.styleSheets[i].disabled = true;
        }
    }

    return;
}


const getAnzahlTiere = () => parseInt(document.getElementById('anzahlTiere').textContent);

const createNewElement = (el) => document.createElement(el);

const elHasListener = (el) => el.classList.contains('is-listen');

const elIsHidden = (el) => el.classList.contains('ue-hide');

const calculateDogAge = (birthday) => {
    moment.locale('de');
    const now = moment();
    const formattedBirthday = moment(birthday.replace('.', '-'), 'DD-MM-YYYY');
    const age = now.diff(formattedBirthday, 'years', true);

    return age.toFixed(1);
}

const getSelectedProductName = () => {
    const products = {
        'OP-Versicherung' : document.getElementById('opGewuenscht').innerText,
        'Krankenversicherung' : document.getElementById('kvGewuenscht').innerText
        };
    let selected = '';

    Object.entries(products).forEach(entry => {
        const [key, value] = entry;

        if (value.toLowerCase() === 'ja') selected = key;
    });

    return selected;
}

export {disableChosenStyles, getAnzahlTiere, createNewElement, elHasListener, elIsHidden, calculateDogAge, getSelectedProductName};