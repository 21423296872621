const getModal = () => document.querySelector('.ue-modal--slide-down');
const getModalButton = () => document.querySelector('.ue-modal__btn .btn');

const toggleOpenClass = () => {
    const modal = getModal();
    const button = getModalButton();
    const parent = modal.parentElement;
    const isOpen = modal.classList.contains('open');

    if (isOpen) {
        modal.classList.remove('open');
        parent.classList.remove('open');
        button.classList.remove('is-active');
    } else {
        modal.classList.add('open');
        parent.classList.add('open');
        button.classList.add('is-active');
    }
    //console.log("ToggleOpen:" + isOpen);
    checkAndScroll();
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

//scroll to top by tablet and mobile
const checkAndScroll = () => {
    if (window.innerWidth < 768) {
        scrollToTop();
    }
}


const setModalParentClass = () => {
    const modal = getModal();
    const parent = modal.parentElement;

    parent.classList.add('modal-parent');
}

const triggerClickOnModalButton = () => {
    const button = getModalButton();
    let isListen = button.classList.contains('is-listen');

    if (!isListen) {
        button.addEventListener('click', (ev) => {
            ev.preventDefault();
            toggleOpenClass();
        });
        button.classList.add('is-listen');
        //console.log("triggerClickOnModalButton:");
    }   
}

const triggerClickOnCloseButton = () => {
    const closeButton = document.querySelector('.modal-header .close');
    let isListen = closeButton.classList.contains('is-listen');

    if (!isListen) {
        closeButton.addEventListener('click', (ev) => {
            toggleOpenClass();
        });
        closeButton.classList.add('is-listen');
        //console.log("triggerClickOnCloseButton:");
    }
}

export { setModalParentClass, triggerClickOnModalButton, triggerClickOnCloseButton };