const setActiveClassForFloatingLabels = () => {
    const textfields = document.querySelectorAll('input.ue-floating-input');
    
    textfields.forEach(field => {        
        addOrRemoveActiveClass(field);              
    });
};

const addOrRemoveActiveClass = (field) => {
    const formGroup = getClosestLabelContent(field);

    if (field.value.length > 0) formGroup.classList.add('is-active');
    
    field.addEventListener('focusin', () => {
        if ((field === document.activeElement && !fieldIsActive(field)) ) formGroup.classList.add('is-active');
    });

    field.addEventListener('focusout', () => {
        if (field.value.length === 0) formGroup.classList.remove('is-active');    
    });    
}

const getClosestLabelContent = (field) => {
    const formGroup = field.closest('.ue-floating-label-content');

    return formGroup;
}

const fieldIsActive = (field) => {
    const formGroup = getClosestLabelContent(field);
    const isActive = formGroup.classList.contains('is-active');

    return isActive;
}

export { setActiveClassForFloatingLabels };