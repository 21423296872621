const _isGewerblicheNutzung = () => {
    const gewerblichList = document.querySelectorAll('#econGewerblicheNutzung li');
    let count = 0;

    gewerblichList.forEach(isGewerblich => {
        if (isGewerblich.innerText === 'ja') count++;
    });

    return count > 0;
};


// Funktion, um das Abwählen der Checkbox zu verhindern
const preventCheckboxUncheck = (event) => {
    event.preventDefault();
    // Stelle sicher, dass die Checkbox immer markiert bleibt
    this.checked = true;
};

const _disableBaustein = (baustein, checkbox) => {
    if (baustein && checkbox) {
        baustein.style.opacity = 0.5;
        checkbox.style.pointerEvents = "none";

        // Füge den EventListener hinzu, um das Abwählen der Checkbox zu verhindern
        checkbox.addEventListener('click', preventCheckboxUncheck);
    }
};

const _enableBaustein = (baustein, checkbox) => {
    if (baustein && checkbox) {
        baustein.style.opacity = 1;
        checkbox.style.pointerEvents = "auto"; // Stelle die pointerEvents wieder auf "auto"

        // Entferne den EventListener, um das Abwählen der Checkbox zu ermöglichen
        checkbox.removeEventListener('click', preventCheckboxUncheck);

        // Stelle sicher, dass die Checkbox nicht mehr als disabled markiert ist
        checkbox.disabled = false;
    }
};

const checkBestLeistung = () => {
    const baustein = document.querySelector(".ue-best-leistung");
    const checkbox = baustein.querySelector('[data-econ-property="tierversicherung-zusatzProdukt-elemente-1-gewuenscht"]');

    if (_isGewerblicheNutzung()) {
        _disableBaustein(baustein, checkbox);
    } else {
        _enableBaustein(baustein, checkbox);
    }
};

export { checkBestLeistung };