import { changeProgressBarWidth } from './progressBar.js';
import SlimSelect from 'slim-select';

const _isSelected = (select) => {
    const selected = select.getSelected();

    if (selected.length > 0) return true;

    return false;
}

const _setSelectedClass = (select, contentContainer) => {
    //console.log(_isSelected(select));
    if (_isSelected(select)) contentContainer.classList.add('is-selected');
}

const _setPlaceholderAttr = (select) => {
    const options = select.querySelectorAll('option');
    const firstOption = options[0];

    firstOption.setAttribute('data-placeholder', true);
}

const initSlimselectOnStart = () => {
    const selectFields = document.querySelectorAll('body.tarifdaten .econ-form-control__select select');

    selectFields.forEach((field, index) => {
        if (field !== null) {
            const contentContainer = field.parentElement;
            let selected = contentContainer.classList.contains('is-selected');
            let isLoaded = contentContainer.querySelector('.ss-main');

            //$('#'+field.id).chosen("destroy"); // muss ggfs. wieder entfernt werden

            if (!selected && !isLoaded) {
                let select = new SlimSelect({
                    select: field,
                    settings: {
                        contentLocation: contentContainer,
                        contentPosition: 'relative',
                        placeholderText: 'Ausfüllen oder wählen',
                        searchText: 'Nichts gefunden',
                        searchPlaceholder: 'Suche...',
                    },
                    events: {
                        beforeOpen: () => {
                            contentContainer.classList.add('is-active');
                        },
                        afterClose: () => {
                            contentContainer.classList.remove('is-active');
                            _setSelectedClass(select, contentContainer);
                        },
                        afterChange: (newVal) => {
                            if (!_isSelected(select)) {
                                changeProgressBarWidth();
                            } 
                        }
                    },
                });
            }
        }
    });
}

const initSlimSelectOnTitle = () => {
    const selectField = document.querySelector('body:not(.tarifdaten, .tarifauswahl) .econ-form-control__select select');

    if (selectField !== null) {
        const contentContainer = selectField.parentElement;
        let selected = contentContainer.classList.contains('is-selected');
                
        if (!selected) {
            let select = new SlimSelect({
                select: selectField,
                settings: {
                    contentLocation: contentContainer,
                    contentPosition: 'relative',
                    showSearch: false,
                },
                events: {
                    beforeOpen: () => {
                        contentContainer.classList.add('is-active');
                    },
                    afterClose: () => {
                        contentContainer.classList.remove('is-active');
                        _setSelectedClass(select, contentContainer);
                    },
                },
            });
        }
    }    

}

const initSlimSelectOnBestandskunde = () => {
    const selectField = document.querySelector('[data-econ-property="antrag-antragsteller1-kunde-vorhanden"]');

    if (selectField !== null) {
        const contentContainer = selectField.parentElement;
        let isRegistered = selectField.classList.contains('is-registered');

        _setPlaceholderAttr(selectField);

        if (!isRegistered) {
            let select = new SlimSelect({
                select: selectField,
                settings: {
                    contentPosition: 'absolute',
                    showSearch: false,
                    placeholderText: 'Bitte wählen',
                },
                events: {
                    beforeOpen: () => {
                        contentContainer.classList.add('is-active');
                    },
                    afterClose: () => {
                        contentContainer.classList.remove('is-active');
                        _setSelectedClass(select, contentContainer);
                    },
                },
            });

            selectField.classList.add('is-registered');
        }
    }    

}
export { initSlimselectOnStart, initSlimSelectOnTitle, initSlimSelectOnBestandskunde };
