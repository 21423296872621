import { getActiveTarif, getActiveProduct } from "./product.js";
import {elIsHidden} from "./helper.js";

const _activeProduct = () => getActiveProduct();
const _activeTarif = () => getActiveTarif(getActiveProduct());

const _showBausteine = () => {
    const bausteine = document.querySelector('.ue-bausteine');
    bausteine.classList.remove("ue-disabled");
}

const _showBausteinContent = (content) => {
    if(elIsHidden(content)) content.classList.remove('ue-hide');
}

const _hideBausteinContent = (content) => {
    if(!elIsHidden(content)) content.classList.add('ue-hide');
}
const _showSonderOpContentByTarif = () => {
    const tarif = _activeTarif();
    const baustein = document.querySelector('.ue-sonder-op')
    const basisContent = baustein.querySelector('.ue-show__for-basis');
    const basisPreis = baustein.querySelector('#ue-sonder-op--basis');
    const premiumContent = baustein.querySelector('.ue-show__for-premium');
    const premiumPreis = baustein.querySelector('#ue-sonder-op--premium');
    const premiumPlusContent = baustein.querySelector('.ue-show__for-premium-plus');
    const premiumPlusPreis = baustein.querySelector('#ue-sonder-op--premium-plus');

    switch (tarif) {
        case 'basis':
            _showBausteinContent(basisContent);
            _showBausteinContent(basisPreis);
            _hideBausteinContent(premiumContent);
            _hideBausteinContent(premiumPreis);
            _hideBausteinContent(premiumPlusContent);
            _hideBausteinContent(premiumPlusPreis);
            break;
        case 'premium':
            _showBausteinContent(premiumContent);
            _showBausteinContent(premiumPreis);
            _hideBausteinContent(basisContent);
            _hideBausteinContent(basisPreis);
            _hideBausteinContent(premiumPlusContent);
            _hideBausteinContent(premiumPlusPreis);
            break;
        case 'premium-plus':
            _showBausteinContent(premiumPlusContent);
            _showBausteinContent(premiumPlusPreis);
            _hideBausteinContent(basisContent);
            _hideBausteinContent(basisPreis);
            _hideBausteinContent(premiumContent);
            _hideBausteinContent(premiumPreis);
            break;
    }
}

const _showSonderOpContentByProduct = () => {
    const kvContentEls = document.querySelectorAll('.ue-show-for-kv');
    const activeProduct = _activeProduct();

    switch (activeProduct) {
        case 'op':
            kvContentEls.forEach(contentEl => {
                _hideBausteinContent(contentEl);
            })

            break;
        case 'kv':
            kvContentEls.forEach(contentEl => {
                _showBausteinContent(contentEl);
            })
            break;
    }
}

const calculateBausteinPrices = () => {
    const rehaPriceEl = document.getElementById('rehaPrice');
    const rehaPrice = parseFloat(rehaPriceEl.innerText.replace(' €', '').replace(',', '.'));
    const zahnZusatzPriceEl = document.getElementById('zahnzusatzPrice');
    const zahnZusatzPrice = parseFloat(zahnZusatzPriceEl.innerText.replace(' €', '').replace(',', '.'));
    const anzahlTiere = parseInt(document.getElementById('ue-anzahl-tiere').innerText);
    //console.log(rehaPrice, zahnZusatzPrice, anzahlTiere);

    if (anzahlTiere > 1) {
        rehaPriceEl.innerText = (rehaPrice * anzahlTiere).toFixed(2).replace('.', ',') + ' €';
        zahnZusatzPriceEl.innerText = (zahnZusatzPrice * anzahlTiere).toFixed(2).replace('.', ',') + ' €';
    }
}

const checkBausteine = () => {
    //Case 1: Kein Hauptprodukt gewählt 
    if(_activeTarif() == undefined) {
        return;
    }

    _showBausteine();
    _showSonderOpContentByTarif();
    _showSonderOpContentByProduct();
}

export { calculateBausteinPrices, checkBausteine };

