import Splide from '@splidejs/splide';
import { getActiveProduct, getActiveTarif, setActiveTarifClass, setActiveTarifButtonText } from "./product.js";

// Doku: https://splidejs.com/guides/options/

const buildProductSlider = () => {
    const isProductActive = (getActiveTarif(getActiveProduct()) === 'basis' || getActiveTarif(getActiveProduct()) === 'premium' || getActiveTarif(getActiveProduct()) === 'premium-plus') ? true : false;
    const activeTarif = getActiveTarif(getActiveProduct());
    let activeSlide = 1;
    const splide = document.querySelector('.splide');

    switch (activeTarif) {
        case 'basis':
            activeSlide = 0;
            break;
        case 'premium':
            activeSlide = 1;
            break;
        case 'premium-plus':
            activeSlide = 2;
            break;
        default:
            activeSlide = 1;
            break;
    }

    const sliderOptions = {
        perPage:    1,
        start:      activeSlide,
        gap:        '1rem',
        width:      '85vw',
        autoHeight: true,
        arrows:     false,
        mediaQuery: 'min',
        breakpoints: {
            768: {
                width:      '48.5rem',
                destroy: true,
            },
        }
    }

    let slider = new Splide('.splide', sliderOptions);
    let isMounted = splide.classList.contains('is-mounted');

    if (!isMounted) {
        slider.mount();

        if (isProductActive) {
            setActiveTarifButtonText();
            setActiveTarifClass(getActiveProduct());
        }

        splide.classList.add('is-mounted');
    }


}

export {buildProductSlider};