const getFieldsToListen = () => {
    const dogs = [
        {
            'name': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-0-name"]'),
            'race': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-0-rasse-bezeichnung"]'),
            'dateOfBirth': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-0-geburtsdatum"]'),
            'sex': document.querySelectorAll('[data-econ-property="tierversicherung-tier-elemente-0-geschlecht"]')
        },
        {
            'name': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-1-name"]'),
            'race': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-1-rasse-bezeichnung"]'),
            'dateOfBirth': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-1-geburtsdatum"]'),
            'sex': document.querySelectorAll('[data-econ-property="tierversicherung-tier-elemente-1-geschlecht"]')
        },
        {
            'name': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-2-name"]'),
            'race': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-2-rasse-bezeichnung"]'),
            'dateOfBirth': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-2-geburtsdatum"]'),
            'sex': document.querySelectorAll('[data-econ-property="tierversicherung-tier-elemente-2-geschlecht"]')
        },
        {
            'name': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-3-name"]'),
            'race': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-3-rasse-bezeichnung"]'),
            'dateOfBirth': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-3-geburtsdatum"]'),
            'sex': document.querySelectorAll('[data-econ-property="tierversicherung-tier-elemente-3-geschlecht"]')
        },
        {
            'name': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-4-name"]'),
            'race': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-4-rasse-bezeichnung"]'),
            'dateOfBirth': document.querySelector('[data-econ-property="tierversicherung-tier-elemente-4-geburtsdatum"]'),
            'sex': document.querySelectorAll('[data-econ-property="tierversicherung-tier-elemente-4-geschlecht"]')
        },
    ]

    const currentFormFields = dogs.filter(dog => (dog.name && dog.race && dog.dateOfBirth && dog.sex));

    return currentFormFields;
}

const getProgressBar = () => document.querySelector('.econ-progress .econ-progress__bar');
const getFieldsCount = (object) => parseInt(Object.entries(object).length);

const initProgressBar = () => {
    const progressBar = getProgressBar();

    progressBar.style.width = 0 + '%';
    progressBar.setAttribute('aria-valuenow', '0');
    showProgressBarWidth();
}

const initProgressBarByAddRemoveButton = () => {
    const fieldsToListen = getFieldsToListen();
    const widthPerInput = calculateWidthPerInput();
    let currentWidth = getProgressBarWidth();
    let count = 0;

    fieldsToListen.forEach(fields => {

        for (const [fieldName, field] of Object.entries(fields)) {
            
            switch (fieldName) {
                case 'race':
                    if (field.selectedIndex > 0) count++;
                break;
                case 'sex':
                    field.forEach(radio => {
                        if (radio.checked) count++;
                    });
                break;
                default:
                    if (field.value) count++
            }
        }
    });

    currentWidth = count * widthPerInput;
    setProgressBarWidth(currentWidth);
    document.querySelector('.current-progress').textContent = currentWidth + ' %';
}

const changeProgressBarWidth = (sub = false) => {
    const widthPerInput = calculateWidthPerInput();
    const textEl = document.querySelector('.current-progress');
    let currentWidth = getProgressBarWidth();

   // console.log(widthPerInput, currentWidth);
    (sub) ? currentWidth -= widthPerInput : currentWidth += widthPerInput;
//console.log(currentWidth);
    setProgressBarWidth(currentWidth);
    textEl.textContent = currentWidth + ' %';
}

const triggerClickOnRadio = (radios) => {
    let isChecked =  false;
    
    for(const radio of radios){
        if (!isChecked) {
            radio.addEventListener('click', (evt) => {
                if (radio.checked) isChecked = true;
            }, {once: true});
        }
    };
    
    if(isChecked) changeProgressBarWidth();
}

const calculateWidthPerInput = () => {
    const fields = getFieldsToListen();
    const progressMax = 100;

    let fieldsCount = 0;
    let progressWithFactor = 0;
    let widthPerInput = 0;    

    fields.forEach(field => {
        fieldsCount += getFieldsCount(field);
    });

    progressWithFactor += fieldsCount;
    widthPerInput = progressMax / progressWithFactor;

    return widthPerInput
}

const getProgressBarWidth = () => {
    const maxWidth = 100;
    const progressBar = getProgressBar();
    let currentProgressWidth = parseInt(progressBar.style.width.replace('%', ''));

    if (currentProgressWidth>maxWidth) currentProgressWidth = maxWidth;

    return currentProgressWidth;
}

const setProgressBarWidth = (currentWidth) => {
    const progressBar = getProgressBar();

    progressBar.style.width = currentWidth + '%';
    progressBar.setAttribute('aria-valuenow', currentWidth);
}

const changeProgressbarWidthOnInput = (addRemove = 'add') => {

    const fieldsToListen = getFieldsToListen();   

    fieldsToListen.forEach(fields => {

        for (const [fieldName, field] of Object.entries(fields)) {
            
            switch (fieldName) {
                case 'name':
                    field.addEventListener('blur', (evt) => { 
                        if (evt.target.value) {
                            changeProgressBarWidth();
                        } else {
                            changeProgressBarWidth(true);
                        }
                    }, {once: true});
                    break;

                case 'sex':
                    triggerClickOnRadio(field);
                    break;

                default:
                break;
            }
        }
    });    
}

const showProgressBarWidth = () => {
    const header = document.querySelector('.tarifdaten .content-header');
    const progress = header.querySelector('.econ-progress');
    const displayWidthEl = document.createElement('div');
    const displayWidthElExist = (document.querySelector('.current-progress')) ? true : false;

    displayWidthEl.classList.add('current-progress');
    displayWidthEl.innerHTML = '0 %';

    if (progress && !displayWidthElExist) progress.parentElement.insertBefore(displayWidthEl, progress);
}

const setBreadcrumbCounter = () => {
    const counter = document.querySelectorAll('.econ-breadcrumb__counter');

    counter.forEach(count => {
        let index = count.innerHTML;
        index -= 1;
        count.innerHTML = index;
    })
}

const removeProgressBarWidth = () => {
    const progressWidthEl = document.querySelector('.current-progress');
    if (progressWidthEl) progressWidthEl.remove();
}

export { initProgressBar, initProgressBarByAddRemoveButton, changeProgressBarWidth, changeProgressbarWidthOnInput, setBreadcrumbCounter, removeProgressBarWidth };