import { setActiveClassOnRadios, setBodyClassByPageContent } from "./states.js";
import { setActiveClassForFloatingLabels } from "../../base/floatingLabels.js";
import { moveInfoIconsToHeadline } from "../../base/infoIcons.js";

const _setHtmlLangAttr = () => {
    document.querySelector('html').setAttribute('lang', 'de');
}

const buildStructureForStyling = () => {
    _setHtmlLangAttr();
    setBodyClassByPageContent();
    setActiveClassOnRadios();
    setActiveClassForFloatingLabels();
    moveInfoIconsToHeadline();
}

export default buildStructureForStyling;